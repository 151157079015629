import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../stylus/eventos.sass'
import {
	initAll,createPrefix
} from './include/global.js'

Promise.all([
	import('../libs/OwlCarousel/owl.carousel.min.js'),
	import('../libs/modernizr/modernizr-custom.js')
]).then(() => {
	import("scriptjs").then((module) => {
		const $script = module.default;
		$script("https://www.google.com/recaptcha/api.js?render=6LdxY50UAAAAAHpijDbmVg2cGb2unL7NEZ5wQnZD");
	});
});

$(document).ready(function(){
	initAll();
})

