import {addCurrency} from './utils.js'

//import './analitycs_alk.js';


export function initAll(){

	/*
	function changeLanguage(element) {
        var lang = element.getAttribute('data-lang');
        var flagElement = document.getElementById('iconDropdown3').querySelector('img');
        flagElement.src = '<?php echo $PIC_RNT ?>/flags/' + lang + '.svg';
        flagElement.setAttribute('data-src', '<?php echo $PIC_RNT ?>/flags/' + lang + '.svg');
    } */

	//$(".solicitarModal").modal();
	/*
	$('select option').each(function() {
		var text = $(this).text().toLowerCase();
		$(this).text(text.charAt(0).toUpperCase() + text.slice(1));
	  }); */
	// $('.dropdown-trigger').dropdown({
	// 	onOpenStart: function(dropdown) { 
	// 		$(dropdown).addClass('active')
	// 	},
	// 	onCloseStart: function(dropdown) { 
	// 		$(dropdown).removeClass('active')			
	// 	}
	// });
	// $('select').formSelect({wrapper: $('body')});
	// $('.sidenav').sidenav({edge: 'right', draggable: true});
	// $('.modal').modal({
	// 		dismissible 	:false
	// 	// ,	onOpenStart 	: function(modal, trigger) { 
			
	// 	// }
	// 	,	onOpenStart 		: function(modal, trigger) { 
	// 		let $modal 		= $(modal)
	// 		,	$trigger 	= $(trigger)
	//       	if($modal.attr('id') == 'solicitar-action'){
	//       		let ahorro 		= '',
	//       			id_hist 	= 0,
	//       			valor_mes 	= 0
	//       		if(!isNaN($trigger.attr('data-ahorro_renting'))){
	//       			ahorro 		= addCurrency(1, '$', $trigger.attr('data-ahorro_renting'), 0, true)
	//       			id_hist 	= $trigger.attr('data-id_hist')
	//       			valor_mes 	= $trigger.attr('data-valor_mes') 
	//       		}
	//       		else{
	//       			let $car 	= $trigger.parents('.car').first()
	//       			ahorro  	= addCurrency(1, '$', $car.attr('data-ahorro_renting'), 0, true)	      			
	//       			id_hist  	= $car.attr('data-id_hist')
	//       			valor_mes 	= $car.attr('data-valor_mes') 
	//       		}      		
	//       		$modal.find('.price-ahorro').html(ahorro)
	//       		$modal.find('[name="valor_mes"]').val(valor_mes)
	//       		$modal.find('[name="id_hist"]').val(id_hist)
	//       		createPrefix($('#sol-prefix'))

	//       		if(window['fbq'] != undefined){
	//       			fbq('track', "Lead");
	// 		    }  
	//       	}
	//       	else if($modal.attr('id') == 'newsletter'){
	//       		if(typeof grecaptcha === 'object'){
	// 	      		grecaptcha.ready(function() {
	// 	      			grecaptcha.execute('6LdxY50UAAAAAHpijDbmVg2cGb2unL7NEZ5wQnZD', {action: 'homepage'}).then(function(token) {
	// 	      				$modal.find('button').attr('data-recaptcha', token)
	// 	      			});
	// 				});
	// 	      	}
	// 	      	createPrefix($('#contact-prefix'))
	//       	}
	//       	let $overlay 	= $('.modal-overlay')      		
    //   		,	$inOverlay 	= $('<div style="opacity:0" />')
    //   		,	webp 		= $('html').hasClass('webp')
    //   		$overlay.empty().append($inOverlay)

    //   		if(screen.width >= 993){
    //   			$inOverlay.html('<div style="position: absolute; top: 20%; left: 0; width: 100%; height: 20%; background-image: url(' + (webp ? $modal.attr('data-bgw') : $modal.attr('data-bg')) + ');background-size: cover; background-position: center center;"></div><div style="position: absolute; top: 40%; left: 0; width: 100%; height: 60%; background-color: #FFF;"></div>')
    //   		}
    //   		else{
    //   			$inOverlay.html('<div style="position: absolute; top: 20%; left: 0; width: 100%; height: 35%; background-image: url(' + (webp ? $modal.attr('data-bgw') : $modal.attr('data-bg')) + ');background-size: cover; background-position: center center;"></div><div style="position: absolute; top: 55%; left: 0; width: 100%; height: 45%; background-color: #FFF;"></div>')	
    //   		}
    //   		$overlay.animate({'opacity': 1}, 1000, function(){
    //   			$inOverlay.animate({'opacity': 1}, 2000)
    //   		})
    //   	},
    // });

	// $("a[href='#footer']").click(function(event) {
	// 	event.preventDefault()
	// 	event.stopPropagation()
	//   	$("html, body").animate({ scrollTop: 0 }, "slow");
	// });

	// $('a[data-scroll="true"]').click(function(event) {
	// 	let id = $(this).attr('href')
	// 	if($(id).length != 0){
	// 		$(id).goTo()
	// 	}
	// })
	sendRequest()
	sendSubscribe()	
}


export function initBuscador(){
	$('#ir-buscar').on('click', function(event){
		event.preventDefault()
		event.stopPropagation()

		let $form 	= $(this).parents('form')
		$form.submit()
	})

	$('a.fil').on('click', function(event){
		event.preventDefault()
		event.stopPropagation()
		if($(this).hasClass('selected')){
			$('[name="tamano-carro"]').val('')
			$('[name="tipo-carro"]').val('')

		}
		else{
			$('[name="tamano-carro"]').val($(this).attr('data-value'))
			$('[name="tipo-carro"]').val('')
		}
		
		$(this).parents('form').submit()
	})

	$('a.fil_2').on('click', function(event){
		event.preventDefault()
		event.stopPropagation()
		if($(this).hasClass('selected')){
			$('[name="tipo-carro"]').val('')
			$('[name="tamano-carro"]').val('')
		}
		else{
			$('[name="tipo-carro"]').val($(this).attr('data-value'))
			$('[name="tamano-carro"]').val('')

		}
		
		$(this).parents('form').submit()
	})
}

export function createPrefix($select){
	if($select.attr('data-cargado') === 'true') return false;
	$select.empty()
	$select.append(`<option value="" disabled selected>${x_textos["RNT2078-0"]}</option>`)
	$select.append('data-cargado', 'true')
	for(let i in countries){
		let country 	= countries[i]
		$select.append(`<option value="${country.idPais}" data-icon="/public/img/flags/1x1/${country.iso2Pais.toLowerCase()}.svg" ${idPais == country.idPais ? 'selected' : ''}>${country.preFormatPais}</option>`)
	}

	var instance = M.FormSelect.getInstance($select.get(0));
	instance.destroy();
	$select.formSelect({wrapper: $('body')})
}

/** Enviar Request **/
function sendRequest(){

	var _sendRequest = function ($button, m_show = true){
		if(!checkButton($button)){
			return false;
		}

		let $form 	= $button.parents('form').first();
		if(validarDatosPersonales($form).status){
			$form.find('button').append(' <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>');

			const formData = {};
			// Recorre cada elemento del formulario dentro del contexto de $form
			$form.find('[name]').each(function() {
				const fieldName = $(this).attr('name');
				const fieldValue = $(this).val();
				formData[fieldName] = fieldValue;
			});

			$.ajax({
	            url: '/action/solicitar-renting/',
	            type: 'POST',
	            data: formData,
	            dataType: 'json',
	            beforeSend: function(){
	     			$button.prop('disabled', true)
	     			//createLoader(true)
	            },
				success: function(data) {
					let instance_mod;
					try {
						
					} catch (error) {
						console.log(error);
					}
					$form.find('button .spinner-border').remove();

					console.log(data);
					afterSubmit(data)
					// Habilitar el botón
					$button.prop('disabled', false);
				
					/* REINICIAR FORMULARIO */
					$('#terms-conditions-subs').prop('checked', false);
					$('#name').val('');
					$('#tel').val('');
					$('#email').val('');
				
					// Reiniciar cualquier otro elemento del formulario según sea necesario
				
					// Restablecer el objeto de comercio electrónico en dataLayer
					//dataLayer.push({ ecommerce: null });

					dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
					dataLayer.push({
					  event: 'conversion'
					});
				
					// Enviar evento de conversión a Google Analytics
					if (typeof gtag == "function") {
						gtag('event', 'conversion', { 'send_to': 'AW-720009898/dLguCIn1k6cBEKr1qdcC' });
					}
				
					// Enviar evento de conversión a Facebook Pixel
					// if (window['fbq'] !== undefined) {
					// 	fbq('track', "Purchase", { value: parseInt($('#valor_mes').val()), currency: 'COP' });
					// }
				},
                error: function(xhr, status){
                    console.log(xhr.responseText)
                	M.toast({html: x_textos["RNT2056-1"]})
                	createLoader(false)  
                	$button.prop('disabled', false)
                }
	        });
		}
	}

	$('#frm-solicitar-action').submit(function(event) {

		event.preventDefault()
		event.stopPropagation()
		_sendRequest($('.enviar-solicitud'))
	});   

	$('.enviar-solicitud').click(function(event) {
		event.preventDefault()
		event.stopPropagation()
		_sendRequest($(this))
	});
}

function validarDatosPersonales($form) {
    $form.find('span.helper-text').remove();

    const requiredInputs = $form.find('[required]');
    const emailReg = /^[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/;

    for (let i = 0; i < requiredInputs.length; i++) {
        const $input = $(requiredInputs[i]);

        // Verificar si es un input, select o checkbox
        if ($input.is('input') || $input.is('select')) {
            let inputValue;

            if ($input.is('select')) {
                inputValue = $input.find('option:selected').val();
            } else if ($input.is(':checkbox')) {
                inputValue = $input.is(':checked');
            } else {
                inputValue = $input.val();
            }

            if ($input.is(':checkbox')) {
                if (!inputValue) {
                    putErrMsg($input, "Debes aceptar para continuar");
                    return { status: false, errNumber: 'P9' };
                }
            } else {
                if (inputValue && typeof inputValue === 'string') {
                    const trimmedValue = inputValue.trim();

                    if (trimmedValue === '') {
                        putErrMsg($input, "Este campo es obligatorio");
                        return { status: false, errNumber: 'P1' };
                    }

                    if ($input.attr('type') === 'email' && !emailReg.test(trimmedValue)) {
                        putErrMsg($input, "Ingresa un email válido");
                        return { status: false, errNumber: 'P8' };
                    }
                } else {
					putErrMsg($input, "Este campo es obligatorio");
					return { status: false, errNumber: 'P8' };
                }
            }
        }
    }

    return { status: true };
}





/** Enviar Subscrite **/
function sendSubscribe($button){

	let _sendSubscribe = function($button){
		event.preventDefault()
		event.stopPropagation()

		if(!checkButton($button)){
			return false;
		}
		let $form 	= $button.parents('form').first()
		if(validarSubscribe($form).status){
			$.ajax({
	            url: '/action/contact-renting/',
	            type: 'POST',
	            data: {'name':  	$form.find('[name="name"]').val(),
	            		'tel':  	$form.find('[name="tel"]').val(),
						'prefix': 	$form.find('[name="prefix"]').val(),
	            		'email':  	$form.find('[name="email"]').val(),
	            		'ciudad': $form.find('[name="ciudad"]').val(),
						'tipo': $form.find('[name="tipo"]').val(),
						'uso': $form.find('[name="uso"]').val(),
						'plan': $form.find('[name="plan"]').val(),
						'renta': $form.find('[name="renta"]').val(),
						'reportado': $form.find('[name="reportado"]').val(),
						'actividad': $form.find('[name="actividad"]').val(),
						'antiguedad': $form.find('[name="antiguedad"]').val(),
	            		'msg': 		$form.find('[name="msg"]').val(),
						'hash_purpose': 		$form.find('[name="hash_purpose"]').val(),
	            		'g-recaptcha-response': $button.attr('data-recaptcha')},
	            dataType: 'json',
	            beforeSend: function(){
	     			$button.prop('disabled', true)
	     			//createLoader(true)
	            },
	            success: function(data){
	            	let instance_mod
					try {
						instance_mod = M.Modal.getInstance($('#solicitar-action').get(0));
					} catch (error) {
						console.log(error);
					}
                	//createLoader(false)                	
                	afterSubmit(data, instance_mod)
                	
	            	$('#contact-name').val('')
					$('#contact-tel').val('')
					$('#contact-email').val('')
					$('#contact-city').val('')
					$('#contact-msg').val('')
	            	$('#terms-conditions-subs1').prop('checked', false)

	            	if(typeof gtag == "function"){
	            		gtag('event', 'conversion', {'send_to': 'AW-720009898/dLguCIn1k6cBEKr1qdcC'});
	            	}
	            },
                error: function(xhr, status){
                    console.log(xhr.responseText)
                	M.toast({html: x_textos["RNT2056-1"]})
                	//createLoader(false)  
                	$button.prop('disabled', false)
                }
	        });
		}
	}

	$('#frm-newsletter').submit(function(event) {
		event.preventDefault()
		event.stopPropagation()
		_sendSubscribe($('#enviar-subscribe'))

	});   

	$('#enviar-subscribe').click(function(event) {
		event.preventDefault()
		event.stopPropagation()
		_sendSubscribe($(this))
	}); 
}

function validarSubscribe($form){
    const email_reg = /^[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+$/,
    	  $name  			= $form.find('[name="name"]'),
          $tel  			= $form.find('[name="tel1"]'),
          $city  			= $form.find('[name="idciudad"]'),
          $email 			= $form.find('[name="email"]'),
          $termsConditions 	= $form.find('[name="terms-conditions"]')

	if($name.val().trim() === ''){
		putErrMsg($name, x_textos["RNT2065-1"])
        return {status: false, errNumber: 'P1'}

    }else if( !isNaN($name.val().trim()) ){
    	putErrMsg($name, x_textos["RNT2066-0"])
        return {status: false, errNumber: 'P1'}
    }

	if($tel.val().trim() === ''){
    	putErrMsg($tel, x_textos["RNT2065-1"])
        return {status: false, errNumber: 'P9'}
    }

    if($city.val().trim() === ''){
		putErrMsg($city, x_textos["RNT2065-1"])
        return {status: false, errNumber: 'P1'}

    }else if( !isNaN($city.val().trim()) ){
    	putErrMsg($city, x_textos["RNT2065-1"])
        return {status: false, errNumber: 'P1'}
    }

	if($email.val().trim() === ''){
		putErrMsg($email, x_textos["RNT2065-1"])
        return {status: false, errNumber: 'P7'}

    }else if( !email_reg.test($email.val().trim()) ){
    	putErrMsg($email, x_textos["RNT2064-1"])
        return {status: false, errNumber: 'P8'}
    }

    if(!$termsConditions.is(':checked')){
    	putErrMsg($termsConditions, x_textos["RNT2064-0"])
        return {status: false, errNumber: 'P9'}
    }

    return {status: true}
}

export function afterSubmit(data, modal = undefined){
	console.log('llegue aqui');
    let status      = data.status
    ,   callback    = function(status){
                        if(status.reload){
                            window.location.reload()
                            return true;
                        }   

                        if(status.go_to != ''){
                            window.location.href    = status.go_to       
                            return true;
                        }

                        if(status.close && modal != undefined){
                            modal.close()
                            return true;
                        }    
                    }

    if(status.localstorage){
        for(let keys in status.localstorage){
            window.localStorage.setItem(keys,JSON.stringify(status.localstorage[keys]))
        }
    }

    if(data.mensaje.length != 0){
        afterSubmitToas(data.mensaje, function(){
            callback(status);
			setTimeout(() => {
				$('.alert').css('display', 'block');
			}, 3000);
        });
       
    }
    else{
        callback(status);
    }
}

function afterSubmitToas(messages, callback = undefined){
    if(messages.length == 0){
        if(typeof callback === 'function'){
            callback()
        }
    }
    else{
    	let _messages = messages.map(function(x) {
		    return findText(x);
		 });

		 $('.alert').css('display', 'block');

         let messages_str = _messages.join('<br />')
		 console.log(messages_str)

         // Actualiza el contenido del toast
         //document.querySelector('#idDelToast .toast-body').innerHTML = messages_str;

         // Muestra el Alert
		 var alertElementGeneric = document.getElementById("alertFormGeneric");
		 var alertElementPersonaNatural = document.getElementById("alertFormPersonaNatural");
		 var alertElementEmpresa = document.getElementById("alertFormEmpresa");

		 // Muestra el alert
		 alertElementGeneric.classList.add('show');
		 alertElementPersonaNatural.classList.add('show');
		 alertElementEmpresa.classList.add('show');
		 
		 // Cierra el alert después de 3 segundos
		 setTimeout(function() {
			if(typeof callback === 'function'){
				callback()
			}
			location.reload();
		 }, 3000);
    }
}



function putErrMsg($input, message){
    let $helper = $input.parent().find('.helper-text')
    if($helper.length != 0) $helper.remove()
    $input.removeClass('valid')
    $input.parent().append('<span class="helper-text text-danger fs-6" data-error="Obligatorio" data-success="Correcto">' + message + '</span>')
    $input.focus()
}

// export function createLoader(show = true){
//     let $loader = $('#loader')
//     if($loader.length == 0){
//         $loader = $(
//             `<div id="loader" style="position: fixed; width: 100%; height: 100%; z-index: 10000; left: 0; top: 0; background-color: rgba(0, 0, 0, .5); text-align: center; align-items: center; display: none;">
//                 <div style="width: 100%; text-align: center;">
//                      <div class="preloader-wrapper big active">
//                         <div class="spinner-layer spinner-blue-only">
//                             <div class="circle-clipper left">
//                                 <div class="circle"></div>
//                             </div><div class="gap-patch">
//                                 <div class="circle"></div>
//                             </div><div class="circle-clipper right">
//                                 <div class="circle"></div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>`).appendTo('body')
//     }
//     if(show)
//         $loader.fadeIn('fast', function(){ $(this).css('display', 'flex')})
//     else
//         $loader.fadeOut('fast')
// }

function checkButton($button){
	if($button.prop('disabled')){
		//M.toast({html:x_textos['RNT2028-1']}, 4000)
		return false;
	}
	return true;
}




